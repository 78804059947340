body {
  --main-color: #4499c7;
  --app-background: #fff;
  --main-text-color: #000;
  --main-text-color-inverted: #fff;
  --border-color: var(--main-text-color-inverted);
  --placeholder-color: #868686;
  --draftail-code-block: #e9e9e9;
  --delete-button-color: #ff81a5;
  --back-button-color: #868686;
  background-color: var(--app-background);
  color: var(--main-text-color);
}

::-moz-selection {
  color: var(--main-text-color);
  background: var(--main-color);
}

::selection {
  color: var(--main-text-color);
  background: var(--main-color);
}

.App {
  background: transparent;
}

.homeGreetings {
  color: var(--main-text-color-inverted);
  font-family: Georgia, "Times New Roman", Times, serif;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  /* margin-top: 40px; */
  text-align: center;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--main-text-color-inverted);
}

.homeContent {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.homeBackground {
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  z-index: -10;
  /* margin-top: -100vh; */
}

.App-link {
  color: var(--main-color);
}

.clockBlinker {
  animation: clockBlinker 2s steps(2, start) infinite;
  -webkit-animation: clockBlinker 2s steps(2, start) infinite;
}

@keyframes clockBlinker {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes clockBlinker {
  to {
    visibility: hidden;
  }
}

.clock {
  color: var(--main-text-color-inverted);
  /* margin-top: 0px; */
  /* margin-bottom: -30px; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* display: inherit; */
  /* white-space: pre-line; */
  /* letter-spacing: -0.12em; */
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-size: larger;
  font-weight: light;
}

.clockSettingValue {
  /* font-family: 'Roboto Mono', monospace; */
  /* font-family: 'Cutive Mono', monospace; */
  font-size: inherit;
}

.clock.time {
  font-weight: bolder;
  font-size: 50pt;
}

.clock.suffix {
  font-size: 2em;
  margin-top: 10%;
  font-weight: lighter;
}

.quote {
  color: var(--main-text-color-inverted);
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 50vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: lighter;
  font-size: 1rem;
}

.quoteText {
  white-space: pre-wrap;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: -100px;
}

.writeButton {
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: transparent;
  color: var(--main-text-color-inverted);
  transition-duration: 0.4s;
  border: 2px solid var(--border-color);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.writeButton:hover {
  background-color: var(--main-text-color-inverted);
  color: var(--main-color);
  cursor: pointer;
}

.writeBtnDiv {
  width: 100%;
}

.lfm__typing_effect_text {
  font-weight: bolder;
}

.pageContainer {
  background-color: var(--app-background);
}

.pageContent {
  font-family: Georgia, "Times New Roman", Times, serif;
  background-color: var(--app-background);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: hidden;
  letter-spacing: 0.01em;
  line-height: 2em;
}

.pageContent a {
  /* text-decoration: none; */
  font-weight: 900;
  color: var(--main-text-color);

  background: linear-gradient(
    to bottom,
    var(--main-color) 0%,
    var(--main-color) 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  text-decoration: none;
  transition: background-size 0.3s;
}

.pageContent a:hover {
  /* text-decoration: underline; */
  color: var(--main-text-color-inverted);
  background-size: 4px 50px;
}

.hero-image {
  background: var(--main-color);
  height: 35vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footerPage {
  position: fixed;
  left: 0;
  bottom: 0px;
  /* height: 30px; */
  height: fit-content;
  padding-bottom: 5px;
  width: 100%;
  font-size: smaller;
  z-index: 1000;
}

.footerPage .footerWhite {
  background: var(--app-background);
}

.footerWhite {
  background: var(--app-background);
  color: var(--main-text-color);
}

.footerTransparent {
  color: var(--main-text-color-inverted);
  background: none;
}

.footerPage .footerWhite a {
  text-decoration: none;
  color: var(--main-text-color);
  font-weight: bolder;
}

.footerPage .footerWhite a:hover {
  text-decoration: underline;
}

.footerPage .footerTransparent a {
  text-decoration: none;
  color: var(--main-text-color-inverted);
  font-weight: bolder;
}

.footerPage .footerTransparent a:hover {
  text-decoration: underline;
}

.left {
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}

.right {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}

.faq-row-wrapper {
  background-color: var(--app-background);
  color: var(--main-text-color);
}

.faq-body {
  background-color: var(--app-background);
  color: var(--main-text-color);
}

.faq-row {
  background-color: var(--app-background);
  color: var(--main-text-color);
}

.row-title {
  color: var(--main-text-color) !important;
}

.row-content {
  color: var(--main-text-color) !important;
}

.arrow-image svg {
  fill: var(--main-text-color) !important;
}

.newStoryContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.viewStoryContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.viewStoryContainer .Draftail-Editor {
  margin-top: -1% !important;
}

.storiesByPageTitle {
  width: 100%;
  margin-top: -2%;
  margin-left: 5.5%;
  font-size: 25pt;
  font-weight: bold;
}

.pageTitleCustom {
  font-size: 50px;
  line-height: 1.2em;
}

.Draftail-Toolbar {
  background: var(--app-background) !important;
  border: 0px !important;
}

/* Inline toolbar color */
.draftJsToolbar__button__qi1gf {
  background: var(--app-background) !important;
  color: var(--main-text-color) !important;
}

.draftJsToolbar__button__qi1gf:hover {
  background: var(--main-color) !important;
}

/* Toolbar block */
.draftJsToolbar__blockType__27Jwn {
  background: var(--app-background) !important;
}

.Draftail-ToolbarButton__label {
  font-size: small !important;
  opacity: 0.7;
  font-family: Georgia, "Times New Roman", Times, serif !important;
}

.public-DraftEditorPlaceholder-root {
  max-width: 90% !important;
}

.public-DraftEditorPlaceholder-inner {
  color: var(--placeholder-color) !important;
  font-family: Georgia, "Times New Roman", Times, serif !important;
}

.Draftail-Editor {
  border: none !important;
  /* padding-top: 20%; */
  padding-bottom: 20%;
  background-color: var(--app-background);
}

.DraftEditor-root {
  color: var(--main-text-color) !important;
}

.public-DraftEditor-content {
  font-family: Georgia, "Times New Roman", Times, serif !important;
  letter-spacing: 0.04em;
}
.Draftail-Editor--readonly .DraftEditor-editorContainer {
  font-family: Georgia, "Times New Roman", Times, serif !important;
  opacity: 1 !important;
}

/* Code block */
.public-DraftStyleDefault-pre {
  background: var(--draftail-code-block) !important;
  padding: 10px !important;
}

.saveStoryButton {
  float: right;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--app-background);
  color: var(--main-color);
  border: 2px solid var(--main-color);
  transition-duration: 0.4s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.saveStoryButton:hover {
  cursor: pointer;
  background-color: var(--main-color);
  color: var(--main-text-color-inverted);
  border: 2px solid var(--main-color);
}

.saveStoryButton:focus {
  outline: none;
}

.deleteStoryButton {
  float: right;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--app-background);
  color: var(--delete-button-color);
  border: 2px solid var(--delete-button-color);
  transition-duration: 0.4s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.deleteStoryButton:hover {
  cursor: pointer;
  background-color: var(--delete-button-color);
  color: var(--main-text-color-inverted);
  border: 2px solid var(--delete-button-color);
}

.deleteStoryButton:focus {
  outline: none;
}

.backStoryButton {
  float: left;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--app-background);
  color: var(--back-button-color);
  border: 0px solid var(--back-button-color);
  transition-duration: 0.4s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.backStoryButton:hover {
  cursor: pointer;
  background-color: var(--back-button-color);
  color: var(--main-text-color-inverted);
  border: 0px solid var(--back-button-color);
}

.backStoryButton:focus {
  outline: none;
}

.backHomeButton {
  float: left;
  margin-left: 7%;
  margin-right: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: transparent;
  color: var(--main-text-color-inverted);
  border: 0px solid var(--back-button-color);
  transition-duration: 0.4s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.backHomeButton:hover {
  cursor: pointer;
  background-color: var(--app-background);
  color: var(--main-color);
}

.backHomeButton:focus {
  outline: none;
}

.cancelStoryButton {
  float: right;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--app-background);
  color: var(--back-button-color);
  border: 2px solid var(--back-button-color);
  transition-duration: 0.4s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cancelStoryButton:hover {
  cursor: pointer;
  background-color: var(--back-button-color);
  color: var(--main-text-color-inverted);
  border: 2px solid var(--back-button-color);
}

.cancelStoryButton:focus {
  outline: none;
}

.deleteDialogText {
  font-family: Georgia, "Times New Roman", Times, serif !important;
}

.offlineAlert {
  background: var(--main-color);
}

.popNotif {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 100;
  padding: 20px;
  color: var(--main-text-color-inverted);
  background-color: var(--main-color);
  border: 0px;
  border-radius: 5px;
  max-width: 30vw;
  text-align: center;
}

.popNotif:hover {
  cursor: pointer;
}

.popNotif.popAlert {
  background-color: var(--delete-button-color);
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.EditableSwitch {
  margin-left: 20px !important;
  float: left;
}
.MuiFormControlLabel-root {
  position: absolute !important;
  display: inline !important;
}

.editableContainer {
  position: absolute;
  margin-top: 20px;
  width: fit-content;
  margin-left: 10%;
}

.editableContainer.Home {
  margin-left: 20px;
}

.editableIcon {
  margin-top: 10px;
}

.editableIconRight {
  margin-left: 60px;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--main-text-color-inverted);
  width: 60%;
}

.writeBtnDiv input {
  background: var(--app-background);
  border: 2px solid var(--border-color);
  border-radius: 5px;
  border: 2px solid var(--main-color);
  color: var(--main-text-color);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 30px;
  width: 50%;
  height: 20%;
  margin-top: 40px;
  margin-right: 20px;
}

.writeBtnDiv input:focus {
  border: 2px solid var(--main-color);
  background: var(--app-background);
  color: var(--main-color);
}

.writeBtnDiv input::placeholder {
  color: var(--placeholder-color);
}

.writeBtnDiv input:focus {
  outline: none;
}

.writeBtnDiv {
  width: 85vw;
  display: flex;
  flex-direction: row-reverse;
  align-items: right;
}

.writeButtonStory {
  /* margin-right: 7px; */
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: transparent;
  color: var(--main-color);
  border: 2px solid var(--main-color);
  transition-duration: 0.4s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.06em;
  border-radius: 5px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.writeButtonStory:hover {
  cursor: pointer;
  color: var(--main-text-color-inverted);
  background: var(--main-color);
}

.hero-image-stories {
  background: var(--main-color);
  height: 35vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.pageContainerStories {
  background-color: var(--app-background);
  width: 100%;
  min-height: 100vh;
}

.pageContentStories {
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: hidden;
  letter-spacing: 0.01em;
  line-height: 2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 50%;
}

.noteContainer {
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 10px;
  overflow: hidden;
}

.emptyStory {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  transform: scale(7);
  opacity: 0.1;
}

.paperStory {
  width: 85vw;
  padding-top: 10px;
  padding-bottom: 20px;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10pt;
  font-weight: 400;
  line-height: 1.6em;
  text-align: left;
  background: var(--app-background);
  /* margin-left: 5%; */
}

.paperStoryContent {
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 0.8;
  margin-top: 5px;
  margin-left: 2%;
  margin-right: 2%;
  text-align: justify;
  font-style: italic;
  color: var(--main-text-color);
}

.paperStoryTitle {
  margin-left: 2%;
  margin-right: 2%;
  font-weight: bold;
  font-size: large;
  text-align: justify;
}

.paperStory a {
  text-decoration: none;
  color: var(--main-text-color);
}

.paperStory:hover {
  color: var(--main-text-color);
}

.paperStoryMeta {
  margin-left: 2%;
  margin-right: 2%;
  color: var(--placeholder-color);
  text-align: left;
  font-size: smaller;
}

.emptyStoryText {
  color: var(--placeholder-color);
  white-space: pre-wrap;
  text-align: center;
}

.newStoryTitleField {
  border: 0px solid;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: x-large;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 1.4em;
  background-color: var(--app-background);
  color: var(--main-text-color);
}

.newStoryTitleField:focus {
  outline: none;
}

.newStoryTitleField::placeholder {
  opacity: 0.8;
}

.viewStoryMeta {
  font-size: small;
  color: var(--placeholder-color);
  margin-left: 1.2%;
  margin-top: -2%;
}

.downloadPageContainer {
  width: 100%;
  margin-top: 30vh;
  line-height: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.downloadPageContainer p {
  margin-top: 50px;
  text-align: center;
  color: var(--placeholder-color);
  font-weight: light;
}

.downloadPageContainer a {
  text-decoration: underline;
  color: var(--main-color);
  font-weight: bold;
}

.loadingSvgContainer {
  width: 100%;
}

.loadingSvgContainer svg {
  display: block;
  margin: auto;
  width: 30%;
}

.downloadProgressBar {
  height: 10px !important;
  width: 40%;
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px !important;
  background-color: var(--placeholder-color) !important;
}

.downloadProgressBar div {
  background-color: var(--main-color) !important;
}

.imageLogoDownload {
  width: 10vw;
  margin: auto;
  animation-name: rotate;
  display: block;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.settingsTab {
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: auto;
}

/* Setting tab color highlight */
.PrivateTabIndicator-colorSecondary-3 {
  background-color: transparent !important;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.jss3 {
  background-color: transparent !important;
}

.MuiButtonBase-root.MuiTab-root {
  text-transform: none;
}

.MuiButtonBase-root.MuiTab-root:hover {
  color: var(--main-text-color-inverted);
  background-color: var(--main-color);
}

.Mui-selected {
  background-color: var(--main-color) !important;
  color: var(--main-text-color-inverted) !important;
}

/* delete popup background */
.MuiDialog-paper {
  background: var(--app-background) !important;
  color: var(--main-text-color) !important;
}
.deleteDialogText {
  color: var(--main-text-color) !important;
}

.settingsTabWrapper {
  width: 80%;
  display: block;
  margin: auto;
}

.settingsPageContainer {
  width: 100%;
  display: block;
  margin: auto;
}

.boxTabPanel {
  margin-left: 20px;
  width: 50vw;
  /* min-height: fit-content; */
  color: var(--main-text-color);
  letter-spacing: 0em;
  line-height: 1.5em;
}

.nameGreetingsInput {
  background: transparent;
  border: 0px solid;
  border-bottom: 1px solid var(--main-text-color-inverted);
  color: var(--main-text-color-inverted);
  font-size: inherit;
  font-family: inherit;
  width: 30%;
}

.nameGreetingsInput:focus {
  outline: none;
}

.settingsInputField {
  border: 0px solid;
  border-bottom: 1px solid var(--main-text-color);
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  background: transparent;
  color: var(--main-text-color);
  margin-top: 5px;
  /* margin-left: 10px; */
}

.settingsInputField:focus {
  outline: none;
  background: transparent;
}

.syncCodeLabel {
  font-weight: bold;
  font-size: xx-large;
  color: var(--main-text-color);
  font-family: Georgia, "Times New Roman", Times, serif;
}

.createSyncCodeSettings {
  border: 0px solid;
  font-family: inherit;
  padding-left: 3px;
  padding-right: 3px;
  font-size: inherit;
  /* font-weight: inherit; */
  font-weight: bolder;
  color: var(--main-text-color);

  background: linear-gradient(
    to bottom,
    var(--main-color) 0%,
    var(--main-color) 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  text-decoration: none;
  transition: background-size 0.3s;
}

.createSyncCodeSettings:hover {
  cursor: pointer;
  color: var(--main-text-color-inverted);
  background-size: 4px 50px;
}

.createSyncCodeSettings:focus {
  outline: none;
}

.paperDeviceList {
  width: 100%;
  height: 70px;
  /* margin-top: 10px; */
  /* padding-top: 5%; */
  /* padding-bottom: 1%; */
  color: var(--main-text-color);
  background: var(--app-background);
  /* border: 1px solid var(--placeholder-color); */
  display: flex;
  flex-direction: row;
  position: relative;
  /* font-size: smaller; */
  /* border-bottom: 1px solid var(--placeholder-color); */
}

.deviceGridContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: 1px solid var(--placeholder-color); */
  border-radius: 1%;
  /* padding: 10px; */
}
.deviceListContainer {
  display: block;
  width: 100%;
}

.deviceNameSync {
  position: absolute;
  top: 50%;
  width: 50%;
  /* margin-top: 5px; */
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.deleteStoryButton.removeDeviceSync {
  position: absolute;
  margin-top: 0px;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* height: 100%; */
  /* margin-right: 0px; */
  /* margin-top: -30px; */
  /* display: block; */
  /* float: right; */
  /* top: 0; */
  /* position: relative; */
}

.actionDeviceNameSync {
  display: flex;
  flex-direction: row;
}

.svgLoaderRemoveDeviceSync {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: 0px;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {

  .clock.time {
    font-size: 20pt;
  }
  .deleteStoryButton.removeDeviceSync {
    margin-top: -45px;
  }

  .deviceNameSync {
    width: 40%;
  }

  .svgLoaderRemoveDeviceSync {
    margin-top: -33px;
  }

  .newStoryContainer {
    width: 95%;
  }

  .viewStoryContainer {
    width: 95%;
  }
  .Draftail-Editor {
    width: 100% !important;
  }
  .editableContainer {
    margin-left: 10px;
  }

  .settingsTabWrapper {
    width: 95vw;
  }

  .boxTabPanel {
    width: 70vw;
  }

  .saveStoryButton.settings {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .deleteStoryButton.settings {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .popNotif {
    width: 90vw;
    max-width: 90vw;
  }
  .pageContent {
    width: 90%;
    height: 100%;
    margin-bottom: 50px;
    display: block;
  }

  .pageContentStories {
    width: 100%;
    overflow-y: hidden;
    display: block;
  }

  .paperStory {
    min-width: 90vw;
    max-width: 90vw;
  }

  .hero-text {
    top: 70%;
    width: 85%;
  }

  .emptyStory {
    margin-top: 50%;
  }

  .writeBtnDiv {
    width: 85%;
    display: flex;
    flex-direction: column-reverse;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    /* margin-top: 20px; */
  }

  .writeBtnDiv input {
    width: 100%;
    margin-top: 0px;
  }

  .writeButtonStory {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 0px;
  }

  .footerPage {
    /* height: 70px; */
    height: fit-content;
    padding-bottom: 5px;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: left;
    width: 94%;
  }
  .hero-text input {
    position: fixed;
    margin-left: -50%;
    margin-top: -70px;
  }

  .backHomeButton {
    margin-top: 5%;
    margin-left: 0px;
  }

  .storiesByPageTitle {
    margin-top: -10%;
    margin-left: 9%;
    font-size: 20pt;
  }

  .downloadPageContainer {
    width: 90%;
    display: block;
    margin-top: 25vh;
    margin-left: auto;
    margin-right: auto;
  }

  .imageLogoDownload {
    width: 30vw;
  }

  .downloadProgressBar {
    width: 90%;
  }
}
